<template>
  <div class="watch-page">
    <section-panel class="watch-chart" title="血压 2023年11月1日 周一" v-for="(item, index) in data" :key="index">
      <chart-base :series="getSeries(item)" :color="color" :legend="legend" :x-axis="xAxis" no-gap></chart-base>
    </section-panel>
    <section-panel class="grid-span-1" title="今日数据"></section-panel>
    <section-panel class="grid-span-2" title="今日血压统计"></section-panel>
    <section-panel class="grid-span-3" title="本周血压统计"></section-panel>
    <section-panel class="grid-span-3" title="本月血压统计"></section-panel>
  </div>
</template>

<script>
import { graphic } from 'echarts';
import SectionPanel from './section-panel';
import ChartBase from './chart-base';

export default {
	components: { SectionPanel, ChartBase },
	data() {
		return {
			color: ['#3480c2', '#81b768', '#e3b55a'],
			xAxis: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
			legend: [
				{ name: '收缩压', icon: 'triangle' },
				{ name: '舒张压', icon: 'triangle' },
				{ name: '脉压差', icon: 'triangle' },
			],
			data: [
				[
					{ data: [120, 118, 109, 120, 118, 109, 96, 96, 120, 118, 109, 96], name: '收缩压' },
					{ data: [40, 40, 63, 73, 63, 40, 63, 73, 63, 93, 73, 63], name: '舒张压' },
					{ data: [20, 20, 40, 60, 32, 40, 20, 40, 60, 32, 60, 32], name: '脉压差' },
				],
				[
					{ data: [120, 118, 109, 120, 118, 109, 96, 96, 120, 118, 109, 96], name: '收缩压' },
					{ data: [40, 40, 63, 73, 63, 40, 63, 73, 63, 93, 73, 63], name: '舒张压' },
					{ data: [20, 20, 40, 60, 32, 40, 20, 40, 60, 32, 60, 32], name: '脉压差' },
				],
				[
					{ data: [120, 118, 109, 120, 118, 109, 96, 96, 120, 118, 109, 96], name: '收缩压' },
					{ data: [40, 40, 63, 73, 63, 40, 63, 73, 63, 93, 73, 63], name: '舒张压' },
					{ data: [20, 20, 40, 60, 32, 40, 20, 40, 60, 32, 60, 32], name: '脉压差' },
				],
			],
		};
	},
	methods: {
		getSeries(data) {
			if (!data) return [];
			return data.map((s, index) => {
				s.type = 'line';
				s.smooth = true;
				s.areaStyle = {
					color: new graphic.LinearGradient(0, 0, 0, 1, [
						{ offset: 0, color: this.color[index] },
						{ offset: 1, color: 'rgba(255, 255, 255, 0.5)' },
					]),
				};
				return s;
			});
		},
	},
};
</script>
